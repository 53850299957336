/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lightgallery@2.8.3/lightgallery.min.js
 * - /npm/lightgallery@2.8.3/plugins/zoom/lg-zoom.min.js
 * - /npm/lightgallery@2.8.3/plugins/hash/lg-hash.min.js
 * - /npm/lightgallery@2.8.3/plugins/thumbnail/lg-thumbnail.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
